import {useState} from 'react';

import {AuthViewHeader} from '../common/AuthViewHeader/AuthViewHeader';
import {NeedToSendPassword} from './NeedToSendPassword';
import {ResetSuccess} from './ResetSuccess';

export const ResetPasswordView = () => {
  const [password, setPassword] = useState<string>('');
  const email = 'email.@email.com';

  const handleSetPassword = (password: string) => {
    setPassword(password);
  };
  return (
    <>
      {email && password ? (
        <>
          <AuthViewHeader enableSignUp={false} />
          <ResetSuccess />
        </>
      ) : (
        <>
          <AuthViewHeader enableSignUp={false} />
          <NeedToSendPassword
            email={email}
            handleSetPassword={handleSetPassword}
          />
        </>
      )}
    </>
  );
};
