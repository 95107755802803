export const employeeItemList = [
  {itemName: 'All', value: ''},
  {itemName: 'Unavailable', value: 'unavailable'},
  {itemName: 'No Response', value: 'no-response'},
  {itemName: 'Selected For Work', value: 'selected-for-work'},
  {itemName: 'Suspended', value: 'suspended'},
];

export const businessItemList = [
  {itemName: 'All Employees', value: ''},
  {itemName: 'Never Worked', value: 'never-worked'},
  {itemName: 'HD Group of Companies', value: 'hd-group-of-companies'},
  {itemName: 'Food Packing', value: 'food-packing'},
  {itemName: 'Coffee Factory', value: 'coffee-factory'},
];

export const tabItemList = [
  {value: 0, label: 'Active'},
  {value: 1, label: 'Trained'},
  {value: 2, label: 'Unqualified'},
  {value: 3, label: 'Disciplined'},
  {value: 4, label: 'Unverified'},
  {value: 5, label: 'Deleted'},
];

export const allWorkerItemList = [
  {itemName: 'Active', value: ''},
  {itemName: 'Trained', value: 'trained'},
  {itemName: 'Unqualified', value: 'unqualified'},
  {itemName: 'Disciplined', value: 'disciplined'},
  {itemName: 'Unverified', value: 'unverified'},
  {itemName: 'Deleted', value: 'deleted'},
];
