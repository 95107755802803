import {useState, SyntheticEvent} from 'react';

import {Box, Button, Stack, Typography, TextField} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';

import moment from 'moment';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import {MainViewTabs} from '../common/MainViewTabs/MainViewTabs';
import {MainViewContainer} from '../common/MainViewContainer/MainViewContainer';
import {DSSelect} from '../common/Select/Select';
import {
  employeeItemList,
  businessItemList,
  tabItemList,
  allWorkerItemList,
} from './const';
import EnhancedTable from './MainTable';

export const MainView = () => {
  const [dateValue, setDateValue] = useState<string | null>(
    moment().format('l')
  );
  const handleDateChange = (newValue: string | null) => {
    setDateValue(newValue);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const HeaderRightComponent = (
    <Box>
      <Button
        fullWidth
        variant="contained"
        sx={{
          backgroundColor: 'primary.main',
          color: 'secondary.main',
        }}
      >
        Daily Status
      </Button>
    </Box>
  );

  return (
    <MainViewContainer
      title="All Workers"
      topNavLabel="general labor"
      HeaderRightComponent={HeaderRightComponent}
    >
      <Stack
        direction="column"
        spacing={4}
        fontWeight="fontWeightBold"
        sx={{
          width: {
            md: '100%',
          },
        }}
      >
        {/* --- Tabs --- */}
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MainViewTabs
            tabItemList={tabItemList}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
          />
        </Box>

        {/* --- substitute select for tabs in small screen --- */}
        <Box
          display={{xs: 'flex', sm: 'none'}}
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Typography
            variant="body1"
            component="h2"
            width={{xs: '6.5rem', sm: '7', md: 'auto'}}
            whiteSpace="nowrap"
            textAlign={{xs: 'end', md: 'start'}}
          >
            Candidates
          </Typography>
          <Box minWidth="12rem">
            <DSSelect itemList={allWorkerItemList} />
          </Box>
        </Box>

        {/* --- Date Picker --- */}
        <Box
          display="flex"
          flexDirection={{xs: 'column', md: 'row'}}
          alignItems="center"
          justifyContent={{sm: 'space-between'}}
          gap={{xs: 1, md: 0}}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box width={{xs: '7rem', lg: '8rem'}}>
              <Button
                startIcon={<KeyboardArrowLeft />}
                fullWidth
                sx={{
                  whiteSpace: {sm: 'nowrap'},
                  justifyContent: 'end',
                  '& .MuiButton-startIcon': {
                    mx: 0,
                  },
                }}
              >
                previous day
              </Button>
            </Box>
            <Box maxWidth={{xs: '10rem', sm: '12rem'}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={dateValue}
                  onChange={handleDateChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          textAlign: 'center',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box width={{xs: '7rem'}}>
              <Button
                endIcon={<KeyboardArrowRight />}
                sx={{
                  whiteSpace: 'nowrap',
                  justifyContent: 'flex-start',
                  '& .MuiButton-endIcon': {
                    mx: 0,
                  },
                }}
              >
                next day
              </Button>
            </Box>
          </Box>
          <Box
            minWidth={{xs: '12rem'}}
            display="flex"
            alignItems="center"
            justifyContent={{xs: 'center', md: 'end'}}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                px: 0,
                backgroundColor: 'primary.main',
                color: 'secondary.main',
              }}
            >
              availability filter
            </Button>
          </Box>
        </Box>

        {/* --- Business/ All Employee Select --- */}
        <Box
          display="flex"
          flexDirection={{xs: 'column', md: 'row'}}
          alignItems="center"
          justifyContent={{sm: 'space-between'}}
          gap={{xs: 1, md: 0}}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Typography
              variant="body1"
              component="h2"
              width={{xs: '6.5rem', sm: '7', md: 'auto'}}
              whiteSpace="nowrap"
              textAlign={{xs: 'end', md: 'start'}}
            >
              Business
            </Typography>
            <Box minWidth="12rem">
              <DSSelect itemList={businessItemList} />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Typography
              variant="body1"
              component="h2"
              width={{xs: '6.5rem', sm: '7', md: 'auto'}}
              whiteSpace="nowrap"
            >
              Worker Status
            </Typography>

            <Box minWidth="12rem">
              <DSSelect itemList={employeeItemList} />
            </Box>
          </Box>
        </Box>
        <EnhancedTable />
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '90%',
              lg: '50%',
            },
            pt: '1rem',
          }}
        >
          <Typography variant="body1" component="h2">
            BLUE text workers have a SIN that will expire in SIXTY (60) days or
            less PURPLE text workers have a SIN that will expire in THIRTY (30)
            days or less RED workers have an expired or missing SIN
          </Typography>
        </Box>
      </Stack>
    </MainViewContainer>
  );
};
