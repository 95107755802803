import {
  Box,
  Button,
  Divider,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Paper,
} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';

const longText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

const messages = [
  [
    {label: 'From', value: 'person1'},
    {label: 'To', value: 'person2'},
    {label: 'Message content', value: longText},
    {label: 'Total characters', value: '11 characters'},
    {label: 'Message parts', value: '1 part'},
    {label: 'Cost of this message', value: '$0.04'},
    {label: 'Total session cost', value: '$0.06'},
  ],
];

export const PreViewMessageInModal = () => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={{xs: 3}}
      >
        <Button
          variant="outlined"
          fullWidth
          sx={{width: {xs: '8rem', md: '10rem'}}}
          startIcon={<KeyboardArrowLeft />}
        >
          Previous
        </Button>

        <Button
          variant="outlined"
          fullWidth
          sx={{width: {xs: '8rem', md: '10rem'}}}
          endIcon={<KeyboardArrowRight />}
        >
          Next
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          border: 1,
          borderRadius: 0,
          borderColor: 'secondary.dark',
          mb: {xs: 4},
        }}
      >
        <Table
          sx={{minWidth: {xs: 300, md: 500}}}
          aria-label="customized table"
        >
          <TableBody>
            {messages.map(message =>
              message.map(row => (
                <TableRow hover key={row.label}>
                  <TableCell
                    align="right"
                    sx={{
                      p: {xs: 1, md: 2},
                      width: {xs: '5rem', md: '13rem'},
                      maxWidth: {xs: '5rem', md: '13rem'},
                      bgcolor: 'secondary.light',
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box>
        <Button variant="text" startIcon={<KeyboardArrowLeft />}>
          Back to editing
        </Button>
      </Box>
    </>
  );
};
