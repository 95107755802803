import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import {LanguageOutlined} from '@mui/icons-material';

import {languageList} from './languageSelectItem';

interface DSLanguageSelectProps {
  language: string | undefined;
  handleLanguageChange: (event: SelectChangeEvent) => void;
}

export const DSLanguageSelect = ({
  language,
  handleLanguageChange,
}: DSLanguageSelectProps) => {
  return (
    <FormControl fullWidth sx={{position: 'relative'}}>
      <InputLabel
        htmlFor="language"
        sx={{
          top: '-0.5rem',
          left: '-0.8rem',
          color: 'primary.main',
        }}
        shrink={false}
      >
        <LanguageOutlined />
      </InputLabel>

      <Select
        id="language"
        value={language}
        displayEmpty
        onChange={handleLanguageChange}
        sx={{
          fontSize: 'body1',
          backgroundColor: 'white',
          '& .MuiSelect-select': {
            textAlign: 'center',
            justifyContent: 'center',
          },
          '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
            backgroundColor: 'white',
            width: '5rem',
            textAlign: 'center',
          },
          '&:focus': {
            outline: 'none',
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            border: 'none',
          },
        }}
      >
        {languageList.map(language => (
          <MenuItem
            key={language.label}
            value={language.value}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {language.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
