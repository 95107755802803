import {useNavigate} from 'react-router-dom';

import {
  Stack,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import {EmailOutlined} from '@mui/icons-material';

interface NeedToSendEmailProps {
  handleSetEmail: (val: string) => void;
}
export const NeedToSendEmail = ({handleSetEmail}: NeedToSendEmailProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="h3" component="h2">
          Password recovery
        </Typography>
        <Typography variant="body1" component="h2">
          Enter the email you use
        </Typography>
      </Stack>
      <Box
        sx={{
          maxWidth: {xs: '90%', sm: '20rem', md: '28rem'},
        }}
      >
        <Stack direction="row">
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Work email"
            fullWidth={true}
            sx={{
              textAlign: 'left',
              '& .MuiInputBase-root': {
                height: '100%',
                maxHeight: '5rem',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{px: '1.5rem', height: '3.5rem'}}
            onClick={() => handleSetEmail('email')}
          >
            Continue
          </Button>
        </Stack>
      </Box>
      <Box>
        <Button sx={{py: '0.5rem', px: '1rem'}} onClick={() => navigate('/')}>
          Back to Sign in
        </Button>
      </Box>
    </Box>
  );
};
