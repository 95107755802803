import {useState} from 'react';

import {AuthViewHeader} from '../common/AuthViewHeader/AuthViewHeader';
import {NeedToSendEmail} from './NeedToSendEmail';
import {EmailSend} from './EmailSend';

export const PasswordForgetView = () => {
  const [email, setEmail] = useState<string>('');
  const handleSetEmail = (email: string) => {
    setEmail(email);
  };

  return (
    <>
      <AuthViewHeader />
      {email ? (
        <EmailSend />
      ) : (
        <NeedToSendEmail handleSetEmail={handleSetEmail} />
      )}
    </>
  );
};
