import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import 'moment-timezone';

import {appTheme} from './Theme';
import {CssBaseline, ThemeProvider} from '@mui/material';
import RouteErrorView from './components/RouteErrorView/RouteErrorView';
import {MainView} from './components/MainView/MainView';
import {PasswordForgetView} from './components/PasswordForgetView/PasswordForgetView';
import {ResetPasswordView} from './components/ResetPasswordView/ResetPasswordView';
import {SignInView} from './components/SignInView/SignInView';

import '../assets/scss/app.scss';
import {ResetSuccess} from './components/ResetPasswordView/ResetSuccess';
import {BrowserTracing} from '@sentry/tracing';
import {NotificationView} from './components/NotificationView/NotificationView';

import {AuthProvider} from './providers/AuthProvider';
import {PrivateRoute} from './routes/PrivateRoute';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  enabled: SENTRY_ENABLED as boolean,
});

const container = document.getElementById('App') as Element;

const reactRoot = createRoot(container, {
  onRecoverableError: error => {
    console.log(`onRecoverableError: ${error}`);
  },
});

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <SignInView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/create-new-text-message',
    element: (
      <PrivateRoute>
        <NotificationView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/main',
    element: (
      <PrivateRoute>
        <MainView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/password-forget',
    element: <PasswordForgetView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/reset-success',
    element: <ResetSuccess />,
    children: [],
    errorElement: <RouteErrorView />,
  },
]);

const RootContainer = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <AuthProvider>
          <RouterProvider router={browserRouter} />
        </AuthProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

reactRoot.render(<RootContainer />);
