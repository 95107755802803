// data
export const allReceivers = [
  {title: 'person1', id: '1', tel: '416-555-0177', group: 'group1'},
  {title: 'person2', id: '2', tel: '416-555-0145', group: 'group2'},
  {title: 'person3', id: '3', tel: '416-555-0142', group: 'group2'},
  {title: 'person4', id: '4', tel: '416-555-0173', group: 'group3'},
];

export const allSenders = [
  {name: 'Sender1', id: '1'},
  {name: 'Sender2', id: '2'},
  {name: 'Sender3', id: '3'},
  {name: 'Sender4', id: '4'},
];

export const repeatItemList = [
  {itemName: 'Yes', value: 'yes'}, // default
  {itemName: 'No', value: 'no'},
];

export const ItemsInModalName = [
  'NONE',
  'contact',
  'template',
  'preview',
] as const;

export const shortText = [
  'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  'short text 2',
  'short text 3',
  'short text 4',
];
