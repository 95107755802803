import {styled, useTheme} from '@mui/material/styles';
import {
  Box,
  Drawer,
  Toolbar,
  IconButton,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {Menu, ChevronLeft, ChevronRight} from '@mui/icons-material';
import {mainViewSideBarItemList} from '../MainViewSideBar/mainViewSideBarItemList';
import {DigistaffLogoFullWhite, DigistaffLogoFullBlack} from '../Image/Image';
import {NestedList} from '../NestedList/NestedList';
import {signOut} from '@digistaff/client-sdk';
import {auth} from '../../../providers/Account';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../../providers/AuthProvider';
import {useContext, useEffect} from 'react';
const drawerWidth = 300;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface MainViewResponsiveNavbarProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

/**
 * responsive navbar for small screen
 *
 * @param open - boolean is Responsive bar opened or not
 * @param onClose - handle close function
 * @param onOpen - handle open function
 * @returns
 */
export const MainViewResponsiveNavbar = ({
  open,
  onClose,
  onOpen,
}: MainViewResponsiveNavbarProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {logOut, currentUser} = useContext(AuthContext);

  useEffect(() => {
    if (currentUser.logOut) navigate('/', {replace: true});
  }, [currentUser, navigate]);

  const handleLogOut = () => signOut(auth).then(() => logOut());

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: 'primary.main',
          boxShadow: 'none',
          display: {
            xs: 'block',
            lg: 'none',
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            boxShadow: 'none',
            outline: 'none',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onOpen}
            edge="start"
            sx={{
              position: 'absolute',
              ...(open && {display: 'none'}),
            }}
          >
            <Menu />
          </IconButton>
          <Box
            component="img"
            onClick={() => navigate('/main')}
            sx={{
              height: '3rem',
              cursor: 'pointer',
              width: '100%',
              display: open ? 'none' : 'flex', //when sidebar open, this image display none
              justifyContent: 'center',
            }}
            alt="Digistaff Logo"
            src={DigistaffLogoFullWhite}
          />
        </Toolbar>
      </AppBar>
      {/* SideBar */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 0,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            component="img"
            onClick={() => {}}
            sx={{
              zIndex: 100,
              mt: '6rem',
              height: '3rem',
              cursor: 'pointer',
            }}
            alt="Digistaff Logo"
            src={DigistaffLogoFullBlack}
            display="flex"
            alignItems="center"
          />
          <IconButton onClick={onClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Container>
          <Box
            sx={{
              width: '15rem',
              mx: 'auto',
              my: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Typography variant="body2" fontWeight="fontWeightBold">
              Welcome,{currentUser?.user?.username}
            </Typography>
          </Box>
          <Stack mt="1rem">
            {mainViewSideBarItemList.map(sideBarItem => (
              <NestedList
                key={sideBarItem.unCollapsedItem.name}
                unCollapsedItem={sideBarItem.unCollapsedItem}
                collapsedItems={sideBarItem?.collapsedItems}
              />
            ))}
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: '1.5rem',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleLogOut()}
              sx={{
                height: '3rem',
                backgroundColor: 'primary.main',
                color: 'secondary.main',
              }}
            >
              Log out
            </Button>
          </Box>
        </Container>
      </Drawer>
    </>
  );
};
