/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
declare let google: any;

import jwt_decode from 'jwt-decode';
import {useNavigate} from 'react-router-dom';
import {useState, useContext, useEffect} from 'react';
import {
  Stack,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import {EmailOutlined, LockOutlined} from '@mui/icons-material';

import {AuthViewHeader} from '../common/AuthViewHeader/AuthViewHeader';
import emailAddress from '../../../assets/js/formvalidation/es6/validators/emailAddress';
import stringLength from '../../../assets/js/formvalidation/es6/validators/stringLength';
import {
  auth,
  signInWithEmailAndPassword,
  googleLogin,
} from '../../providers/Account';

import {AuthContext} from '../../providers/AuthProvider';

export interface ISignInForm {
  email: string;
  password: string;
}

export interface ISignInFormError {
  email: string;
  password: string;
  invalidEmailOrPw: string;
}

export type SignInProps = {
  name: string;
  value: string;
};

export const SignInView = () => {
  const {login, currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const [form, setForm] = useState<ISignInForm>({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState<ISignInFormError>({
    email: '',
    password: '',
    invalidEmailOrPw: '',
  });

  useEffect(() => {
    if (currentUser.user)
      navigate(localStorage.getItem('dsLastPage') || '/main');
  }, [currentUser, navigate]);

  useEffect(() => {
    const googleButtonContainer = document.getElementById('google-sign-in-box');

    google.accounts.id.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID,
      callback: (data: any) =>
        handleGoogleLogin(data.credential, jwt_decode(data.credential)),
    });

    google.accounts.id.renderButton(googleButtonContainer, {
      theme: 'filled_black',
      size: 'large',
      width: '400px', // maximum width is 400
    });

    const handleGoogleLogin = async (token: string, data: any) => {
      const user = {name: data.name, email: data.email};
      googleLogin(auth, token, data.exp, user).then(user => {
        login(user);
      });
    };
  }, [login, navigate]);

  const handleForm = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm({...form, [name]: value});
  };

  /**
   * validate if the email address is valid and the password length is over 8 char.
   * @param e - input value from user
   * return : set formError accordingly
   */
  const validateField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case 'email': {
        const result = emailAddress().validate({
          value,
        });
        result.valid === false
          ? setFormError({
              ...formError,
              [name]: 'The value is not a valid email address',
            })
          : setFormError({...formError, [name]: ''});
        break;
      }
      case 'password':
        {
          const result = stringLength().validate({
            value,
            options: {
              min: 8,
              max: 20,
            },
          });
          result.valid === false
            ? setFormError({
                ...formError,
                [name]: 'The value is not a valid password',
              })
            : setFormError({...formError, [name]: ''});
        }
        break;
    }
  };

  const handleSubmit = () => {
    if (
      Object.values(formError.email).length > 0 ||
      Object.values(formError.password).length > 0
    ) {
      setFormError({
        ...formError,
        invalidEmailOrPw: 'Incorrect Email or Password',
      });
    }

    signInWithEmailAndPassword(auth, form.email, form.password)
      .then(({user}: any) => {
        setFormError({...formError, invalidEmailOrPw: ''});
        login(user);
      })
      .catch(({err}) => {
        alert(err);
        setFormError({...formError, invalidEmailOrPw: err});
      });
  };

  return (
    <>
      <AuthViewHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '5rem',
        }}
      >
        <Grid
          container
          direction="column"
          maxWidth={{xs: '90%', sm: '20rem', md: '400px'}}
        >
          <Grid item>
            <Typography variant="h3" component="h2" fontWeight="fontWeightBold">
              Sign in
            </Typography>
            <Box sx={{mt: '1rem', pb: '1rem'}}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
                id="google-sign-in-box"
              ></div>
            </Box>
          </Grid>
          <Grid item my={1.5}>
            <Typography variant="body1" component="h6">
              or use your email to sign in:
            </Typography>
            <Stack direction="column" spacing={2} mt={2}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Work email"
                name="email"
                type="email"
                value={form.email}
                error={Boolean(formError.email || formError.invalidEmailOrPw)}
                helperText={formError.email}
                onChange={e => handleForm(e)}
                onBlur={e => validateField(e)}
                sx={{
                  textAlign: 'left',
                  '& .MuiInputBase-root': {
                    maxHeight: '5rem',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Password"
                name="password"
                type="password"
                onChange={e => handleForm(e)}
                error={Boolean(
                  formError.password || formError.invalidEmailOrPw
                )}
                helperText={formError.password}
                onBlur={e => {
                  validateField(e);
                }}
                sx={{
                  textAlign: 'left',
                  '& .MuiInputBase-root': {
                    maxHeight: '5rem',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
          <Box sx={{pb: '0.5rem'}}>
            <Button
              sx={{fontSize: 'body2'}}
              onClick={() => navigate('/password-forget')}
            >
              I forgot my password
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              fullWidth={true}
              sx={{fontWeight: 'fontWeightMedium'}}
              disabled={
                Object.values(formError.email).length > 0 ||
                Object.values(formError.password).length > 0
                  ? true
                  : false
              }
              onClick={handleSubmit}
            >
              sign in
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button>Sign in with Single Sign On</Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
