import {useState} from 'react';
import {Stack, Box, Button, SelectChangeEvent} from '@mui/material';
import {DigistaffLogoFullBlack} from '../Image/Image';
import {DSLanguageSelect} from '../DSLanguageSelect/DSLanguageSelect';

interface AuthViewHeaderProps {
  enableSignUp?: boolean;
}

/**
 * Header shows on signin/password forget/reset password page
 *
 * @param enableSignUp - set true to show a signup button on top right corner next to a language select
 * @returns - Authentication Header Component
 */
export const AuthViewHeader = ({enableSignUp = true}: AuthViewHeaderProps) => {
  const [language, setLanguage] = useState('');
  const handleLanguageChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };

  return (
    <Box
      sx={{
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: {xs: '1rem', md: '3rem'},
        pt: {xs: '1rem', md: '2rem'},
      }}
    >
      {/* --- header left --- */}
      <Box
        component="img"
        sx={{
          cursor: 'pointer',
          height: '3rem',
        }}
        onClick={() => {
          console.log('navigate');
          // navigate('/');
        }}
        alt="Digistaff Logo"
        src={DigistaffLogoFullBlack}
      />

      {/* --- header right --- */}
      <Stack direction="row" alignItems="center">
        <DSLanguageSelect
          language={language}
          handleLanguageChange={handleLanguageChange}
        />

        {enableSignUp && (
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              minWidth: {md: '5rem'},
              px: {xs: 0, sm: 0.2},
              height: '3rem',
              ml: '-1rem',
              '&:hover': {backgroundColor: 'black', color: 'white'},
            }}
          >
            sign up
          </Button>
        )}
      </Stack>
    </Box>
  );
};
