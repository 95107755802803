import {MouseEvent, useState} from 'react';

import {alpha} from '@mui/material/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  InputBase,
} from '@mui/material';
import {Search as SearchIcon, AddCircle} from '@mui/icons-material';
import {styled} from '@mui/material/styles';
import {shortText} from './const';

const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
}));

interface Data {
  name: string;
  content: string;
  action: JSX.Element;
}

function createData(name: string, content: string, action: JSX.Element): Data {
  return {
    name,
    content,
    action,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key
): (
  a: {[key in Key]: number | string | Function},
  b: {[key in Key]: number | string | Function}
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: Data[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'content',
    numeric: false,
    disablePadding: false,
    label: 'Content',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const TemplatesInModal = ({
  setMessage,
  handleCloseModal,
}: {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  handleCloseModal: Function;
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const renderActionButton = (text: string) => {
    return (
      <Button
        variant="contained"
        onClick={() => insertMessage(text)}
        sx={{whiteSpace: 'nowrap'}}
      >
        Insert template
      </Button>
    );
  };
  const rows = [
    createData('Friday sale', shortText[0], renderActionButton(shortText[0])),
    createData(
      'VIP customers template',
      shortText[1],
      renderActionButton(shortText[1])
    ),
    createData('Offer', shortText[2], renderActionButton(shortText[2])),
    createData('Marketing', shortText[3], renderActionButton(shortText[3])),
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string | number | Function | ConcatArray<string>
  ) => {
    const selectedIndex = selected.indexOf(name as string);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name as string);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const insertMessage = (text: string) => {
    setMessage(text);
    handleCloseModal();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string | number | Function) =>
    selected.indexOf(name as string) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{width: '100%'}}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{xs: 'column-reverse', md: 'row'}}
        alignItems="center"
        px={1}
        my={2}
      >
        <Box
          mr={{xs: 0, md: 1}}
          mt={{xs: 1, md: 0}}
          width="100%"
          maxWidth={{xs: '13rem'}}
        >
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddCircle />}
            sx={{whiteSpace: 'nowrap', px: 1}}
          >
            New Template
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          maxWidth={{xs: '26rem', md: '15rem'}}
          justifyContent="center"
        >
          <Search>
            <InputBase
              placeholder="Search…"
              inputProps={{'aria-label': 'search'}}
              sx={{
                p: '1rem',
                height: '2.5rem',
                pl: '1rem',
                width: '100%',
              }}
            />
          </Search>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{
              borderRadius: 0,
              backgroundColor: 'secondary.dark',
              height: '2.5rem',
              '&:hover': {backgroundColor: 'secondary.dark'},
            }}
          >
            <SearchIcon sx={{fontSize: '1.5rem'}} />
          </IconButton>
        </Box>
      </Box>
      <Paper sx={{width: '100%', mb: 2}}>
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const isItemSelected = isSelected(row.name);

                  return (
                    <TableRow
                      hover
                      onClick={(event: MouseEvent<unknown>) =>
                        handleClick(event, row.name)
                      }
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name as string}
                    >
                      <TableCell align="left">{row.name as string}</TableCell>
                      <TableCell align="left">
                        {row.content as string}
                      </TableCell>
                      <TableCell align="left">{row.action as string}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
