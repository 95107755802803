import {useState} from 'react';
import {Box, Grid, Button, Typography} from '@mui/material';
import {KeyboardArrowLeft} from '@mui/icons-material';
import {MainViewSideBar} from '../MainViewSideBar/MainViewSideBar';
import {mainViewSideBarItemList} from '../MainViewSideBar/mainViewSideBarItemList';
import {MainViewResponsiveNavbar} from '../MainViewResponsiveNavbar/MainViewResponsiveNavbar';

interface MainViewContainerProps {
  children: React.ReactNode;
  topNavLabel: string;
  title: string;
  HeaderRightComponent?: React.ReactElement;
}

/**
 * Container that contains a top nav and a sidebar wraps a main component on each page
 *
 * @param children - main component
 * @param topNavLabel - navigation button label in top left corner
 * @param title - Page title
 * @param HeaderRightComponent - component in top right corner on the same row as title
 * @returns
 */
export const MainViewContainer = ({
  children,
  topNavLabel,
  title,
  HeaderRightComponent,
}: MainViewContainerProps): React.ReactElement => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box maxWidth="xl" mx="auto">
      <Box
        py={2}
        sx={{
          backgroundColor: {
            xs: 'white',
            md: 'secondary.light',
          },
        }}
      >
        <MainViewResponsiveNavbar
          open={drawerOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        />
        <Grid container>
          {/* ------ SideBar ------- */}
          <Grid item xs={0} lg={3}>
            <MainViewSideBar sideBarItemList={mainViewSideBarItemList} />
          </Grid>
          {/* ------ MainView ------- */}
          <Grid
            item
            xs={12}
            lg={9}
            px={{xs: 1, sm: 2, xl: 3}}
            pt={2}
            onClick={handleDrawerClose}
          >
            {/* --- top Navigation Button */}
            <Button
              variant="text"
              startIcon={<KeyboardArrowLeft />}
              color="secondary"
              sx={{
                mb: '0.5rem',
                px: {xs: 1, md: 4},
                mt: {xs: '3rem', sm: '4rem', lg: 0},
              }}
              onClick={() => {}}
            >
              {topNavLabel}
            </Button>
            <Box
              p={{xs: 0, md: 4}}
              sx={{
                backgroundColor: 'white',
              }}
            >
              {/* --- Main Top Header component --- */}
              <Box
                display="flex"
                flexDirection={{xs: 'column', sm: 'row'}}
                justifyContent="space-between"
                alignItems="center"
                mb={{sm: 2, md: 5}}
              >
                <Box my={{xs: 2, md: 0}}>
                  <Typography variant="h4" component="h2">
                    {title}
                  </Typography>
                </Box>
                {HeaderRightComponent}
              </Box>
              <Box
                pt={4}
                px={{xs: 0, md: 0}}
                sx={{
                  backgroundColor: 'white',
                }}
                overflow="hidden"
              >
                {children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
