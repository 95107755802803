import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  SvgIconProps,
} from '@mui/material';
import {ExpandMore, ExpandLess} from '@mui/icons-material';

interface NestedListProps {
  unCollapsedItem: {
    name: string;
    Icon: React.ComponentType<SvgIconProps>;
    url?: string;
  };
  collapsedItems?: {
    name: string;
    Icon?: React.ComponentType<SvgIconProps>;
    url?: string;
  }[];
}

/**
 * The nestedList/List takes two parameters unCollapsedItem
 * and collapsedItem and return the components
 *
 * @param unCollapsedItem - The Item on a sidebar which is not collapsed
 * @param collapsedItems - A list of the Items on a sidebar which is
 * collapsed and contained by unCollapsedItem
 * @returns - NestedList/List Component
 */
export const NestedList = ({
  unCollapsedItem,
  collapsedItems,
}: NestedListProps) => {
  const [openNestedList, setOpenNestedList] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {/* if collapsed items not exist, then only unCollapsedItem component show */}
      {!collapsedItems ? (
        <>
          <ListItemButton
            onClick={() => {
              console.log('navigate', unCollapsedItem);
              navigate(unCollapsedItem.url as string);
            }}
            sx={{height: '3rem'}}
          >
            <ListItemIcon>
              <unCollapsedItem.Icon sx={{color: 'secondary.dark'}} />
            </ListItemIcon>
            <ListItemText primary={unCollapsedItem.name} />
          </ListItemButton>
        </>
      ) : (
        <>
          <ListItemButton
            onClick={() => {
              setOpenNestedList(prev => !prev);
            }}
            sx={{height: '3rem'}}
          >
            <ListItemIcon>
              <unCollapsedItem.Icon
                sx={
                  !openNestedList
                    ? {color: 'secondary.dark'}
                    : {color: 'primary'}
                }
              />
            </ListItemIcon>
            <ListItemText primary={unCollapsedItem.name} />
            {openNestedList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openNestedList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {collapsedItems &&
                collapsedItems.map(collapsedItem => (
                  <ListItemButton
                    key={collapsedItem.name}
                    sx={{pl: 4}}
                    onClick={() => {
                      navigate(collapsedItem.url as string);
                    }}
                  >
                    <ListItemText primary={collapsedItem.name} />
                  </ListItemButton>
                ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
};
