/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useRef, useEffect} from 'react';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  TextField,
  Typography,
  Button,
  Divider,
  Stack,
  Container,
  Collapse,
  Grid,
} from '@mui/material';
import {Send, Cancel, Visibility, EventAvailable} from '@mui/icons-material';
import {GlobalModal} from '../common/GlobalModal/GlobalModal';
import {ContactListInModal} from './ContactListInModal';
import {PreViewMessageInModal} from './PreViewMessageInModal';
import {TemplatesInModal} from './TemplatesInModal';
import {MainViewContainer} from '../common/MainViewContainer/MainViewContainer';
import {allReceivers, allSenders, ItemsInModalName} from './const';
import {ScheduleMessage} from './ScheduleMessage';

export type ContactListData = {
  title: string;
  id: string;
  tel: string;
  group: string;
};

type modalItemType = typeof ItemsInModalName[number];

export const NotificationView = () => {
  const searchKeyTrigger = 1;
  const [filteredData, setFilteredData] = useState<ContactListData[]>([]);
  const [sender, setSender] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [openScheduleMessage, setOpenScheduleMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalItem, setModalItem] = useState<string>('NONE');
  const inputRef = useRef<HTMLInputElement>(null);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    const tagsAvailable = JSON.parse(
      sessionStorage.getItem('recipients') || '[]'
    );
    if (tagsAvailable.length !== 0) setTags(tagsAvailable);
    let messages;
    const messagesAvailable = sessionStorage.getItem('messages');
    if (typeof messagesAvailable === 'string') {
      messages = JSON.parse(messagesAvailable);
    }
    if (messages) setMessage(messages);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('recipients', JSON.stringify(tags));
    sessionStorage.setItem('messages', JSON.stringify(message));
  }, [tags, message]);

  const handleFilter = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const searchWord: string = target.value.toLowerCase();

    if (searchWord.length > searchKeyTrigger) {
      const newFilter: ContactListData[] = allReceivers.filter(
        (user): boolean => {
          return (
            user.title.toLowerCase().includes(searchWord) ||
            user.tel.includes(searchWord)
          );
        }
      );

      !searchWord ? setFilteredData([]) : setFilteredData(newFilter);
    }
  };

  const handleOpenModal = (modalItem: string) => {
    setOpenModal(true);
    setModalItem(modalItem);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleKeyChange = (e: {key: string}) => {
    if (e.key === 'Enter') {
      setTags([...tags, inputRef.current!.value]);
      inputRef.current!.value = '';
      setFilteredData([]);
    }
    if (e.key === 'Backspace') setFilteredData([]);
  };

  const handleDelete = (value: string) => {
    const newTags = tags.filter(val => val !== value);
    setTags(newTags);
  };

  const handleSelectSender = (event: SelectChangeEvent<typeof sender>) => {
    const {
      target: {value},
    } = event;
    setSender(value);
  };

  const handleRecipientClick = (e: any) => {
    setTags([...tags, e.target.getAttribute('value')]);
    inputRef.current!.value = '';
    setFilteredData([]);
  };

  const handleGroupRecipient = (items: any[]) => {
    items.forEach(item => tags.push(item));

    inputRef.current!.value = '';
    setFilteredData([]);
    handleCloseModal();
  };

  const handleMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    e.target.value ? setMessage(e.target.value) : setMessage('');

  const Tags = ({
    data,
    handleDelete,
  }: {
    data: string;
    handleDelete: Function;
  }) => {
    return (
      <Box
        sx={{
          backgroundColor: 'primary.main',
          height: '30px',
          borderRadius: '20px',
          display: 'flex',
          m: '10px 8px 0 0',
          justifyContent: 'center',
          alignContent: 'center',
          color: '#ffffff',
        }}
      >
        <Stack
          direction="row"
          gap={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              px: 1,
            }}
          >
            {data}
          </Typography>
          <Cancel
            sx={{cursor: 'pointer', fontSize: '1rem', mr: 1}}
            onClick={() => handleDelete(data)}
          />
        </Stack>
      </Box>
    );
  };

  const ItemsInModal = {
    NONE: {Component: <Box />, title: ''},
    contact: {
      Component: (
        <ContactListInModal handleGroupRecipient={handleGroupRecipient} />
      ),
      title: 'List',
    },
    template: {
      Component: (
        <TemplatesInModal
          setMessage={setMessage}
          handleCloseModal={handleCloseModal}
        />
      ),
      title: 'Template',
    },
    preview: {
      Component: <PreViewMessageInModal />,
      title:
        'Message preview for NO_RECIPIENT recipient(out of NUMBER_OF RECIPIENTS)',
    },
  };

  return (
    <>
      <MainViewContainer title="New Text Message" topNavLabel="notification">
        {/* Row for Recipients */}
        <Container maxWidth="md" disableGutters>
          <Stack
            direction="column"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            spacing={1.5}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth={{xs: '100%', sm: '85%'}}
            >
              <Typography
                variant="body1"
                fontWeight="fontWeightBold"
                textAlign={{xs: 'end'}}
                mr={1}
                minWidth={{xs: '4.63rem'}}
              >
                To:
              </Typography>
              <TextField
                size="medium"
                variant="outlined"
                onChange={handleFilter}
                fullWidth
                onKeyDown={handleKeyChange}
                autoComplete="off"
                inputRef={inputRef}
                sx={{
                  height: '150px',
                  flexWrap: 'wrap',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'black',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                inputProps={{
                  style: {marginBottom: '100px', padding: '5px, 0px'},
                }} // inline input
                InputProps={{
                  style: {
                    minHeight: '150px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  },
                  startAdornment: tags.map((data, index) => {
                    return (
                      <Tags
                        data={data}
                        key={index}
                        handleDelete={handleDelete}
                      />
                    );
                  }),
                }}
              />

              <Box minWidth={{xs: '4.7rem', md: '5.1rem', lg: '5.5rem'}}>
                <Button
                  fullWidth
                  onClick={() => handleOpenModal('contact')}
                  sx={{textAlign: 'left'}}
                >
                  List
                </Button>
              </Box>
            </Box>
            {filteredData.length !== 0 && (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid xs={1}></Grid>
                <Grid xs={9.5} sx={{pl: '2px'}}>
                  <Box
                    sx={{
                      height: '100px',
                      overflow: 'hidden',
                      overflowY: 'scroll',
                    }}
                  >
                    {filteredData.map(({title, tel}, key) => (
                      <MenuItem
                        key={key}
                        value={title}
                        onClick={e => handleRecipientClick(e)}
                      >
                        {title} - {tel}
                      </MenuItem>
                    ))}
                  </Box>
                </Grid>
                <Grid xs={1.5}></Grid>
              </Grid>
            )}

            {/* Row for Message sender */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth={{xs: '100%', sm: '85%'}}
            >
              <Typography
                variant="body1"
                fontWeight="fontWeightBold"
                textAlign={{xs: 'end'}}
                mr={1}
                minWidth={{xs: '4.63rem'}}
              >
                From:
              </Typography>
              <Container
                maxWidth={false}
                disableGutters
                sx={{minWidth: {sm: '15rem'}}}
              >
                <FormControl
                  sx={{
                    width: '100%',
                    minHeight: '3rem',
                    outline: 'none',
                  }}
                >
                  <Select
                    fullWidth
                    value={sender}
                    onChange={handleSelectSender}
                    MenuProps={MenuProps}
                    sx={{
                      px: 1,
                      minHeight: '3rem',
                      '&.MuiSelect-select': {
                        display: 'inline-flex',
                        justifyContent: 'left',
                      },
                    }}
                  >
                    {allSenders.map(sender => (
                      <MenuItem key={sender.id} value={sender.name}>
                        {sender.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Container>
              <Box // placeholder to match size to the template row
                height={1}
                minWidth={{xs: '4.7rem', md: '5.1rem', lg: '5.5rem'}}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth={{xs: '100%', sm: '85%'}}
              pb="1rem"
            >
              <Typography
                variant="body1"
                fontWeight="fontWeightBold"
                textAlign={{xs: 'end'}}
                mr={1}
                minWidth={{xs: '4.63rem'}}
              >
                Message:
              </Typography>
              <Container
                maxWidth={false}
                disableGutters
                sx={{minWidth: {sm: '15rem'}}}
              >
                <TextareaAutosize
                  value={message}
                  onChange={e => handleMessage(e)}
                  minRows={5}
                  style={{
                    padding: '0.5rem',
                    width: '100%',
                    backgroundColor: '#dcdcdc',
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{xs: 'center', sm: 'end'}}
                  gap={1}
                >
                  <Box fontSize={{xs: '0.8rem', sm: '0.9rem', md: '1rem'}}>
                    Characters
                    <Box display={{sm: 'inline-block'}} ml={{sm: 1}}>
                      {message.length}
                    </Box>
                  </Box>
                </Box>
              </Container>
              <Box minWidth={{xs: '4.7rem', md: '5.1rem', lg: '5.5rem'}}>
                <Button fullWidth onClick={() => handleOpenModal('template')}>
                  Template
                </Button>
              </Box>
            </Box>

            <Divider flexItem />
            <Box
              display={!openScheduleMessage ? 'flex' : 'none'}
              justifyContent="space-between"
              minWidth={{xs: '100%', sm: '85%'}}
            >
              <Button
                variant="text"
                onClick={() => setOpenScheduleMessage(prev => !prev)}
                startIcon={<EventAvailable />}
              >
                Schedule message
              </Button>
              <Box display="flex" alignItems="center" gap={1}>
                <Button
                  variant="text"
                  onClick={() => handleOpenModal('preview')}
                  startIcon={<Visibility />}
                >
                  Preview message
                </Button>
                <Button variant="contained" endIcon={<Send />}>
                  Send
                </Button>
              </Box>
            </Box>

            {/* Hidden Components (Schedule Message) */}
            <Collapse in={openScheduleMessage}>
              <ScheduleMessage
                openScheduleMessage={openScheduleMessage}
                setOpenScheduleMessage={setOpenScheduleMessage}
                handleOpenModal={handleOpenModal}
              />
            </Collapse>
          </Stack>
        </Container>
      </MainViewContainer>
      <GlobalModal
        open={openModal}
        onClose={handleCloseModal}
        title={ItemsInModal[modalItem as modalItemType].title}
      >
        {ItemsInModal[modalItem as modalItemType].Component}
      </GlobalModal>
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
