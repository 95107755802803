import {signOut} from '@digistaff/client-sdk';
import {Box, Typography, Stack, SvgIconProps, Button} from '@mui/material';
import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth} from '../../../providers/Account';
import {AuthContext} from '../../../providers/AuthProvider';
import {DigistaffLogoFullBlack} from '../Image/Image';
import {NestedList} from '../NestedList/NestedList';

type sideBarItemList = {
  unCollapsedItem: {
    name: string;
    Icon: React.ComponentType<SvgIconProps>;
  };
  collapsedItems?: {
    name: string;
    Icon?: React.ComponentType<SvgIconProps>;
  }[];
};

interface SideBarProps {
  sideBarItemList: sideBarItemList[];
}

/**
 * MainViewSideBar takes two parameters sideBarItemsList and userEmail
 *
 * @param sideBarItemList - A list of nestedList parameters
 * @param userFirstName - user first name
 * @returns - sidebar contains Logo, firstName, nestedList
 */
export const MainViewSideBar = ({sideBarItemList}: SideBarProps) => {
  const navigate = useNavigate();
  const {logOut, currentUser} = useContext(AuthContext);

  useEffect(() => {
    if (currentUser.logOut) navigate('/', {replace: true});
  }, [currentUser, navigate]);

  const handleLogOut = () => signOut(auth).then(() => logOut());

  return (
    <Box
      sx={{
        width: {xs: 340, xl: 360},
        backgroundColor: 'white',
        position: 'fixed',
        overflowY: 'scroll',
        mx: 'auto',
        top: {
          xs: '2rem',
          md: 0,
        },
        bottom: 0,
        px: '2rem',
        display: {
          xs: 'none',
          lg: 'block',
        },
      }}
    >
      <Box
        component="img"
        onClick={() => navigate('/main')}
        sx={{
          height: '3.5rem',
          mt: '2rem',
          cursor: 'pointer',
        }}
        alt="Digistaff Logo"
        src={DigistaffLogoFullBlack}
      />
      <Box sx={{width: '15rem', mx: 'auto', my: '1rem'}}>
        <Typography variant="body2" fontWeight="fontWeightBold">
          Welcome, {currentUser?.user?.username}
        </Typography>
      </Box>
      <Stack>
        {sideBarItemList.map(sideBarItem => (
          <NestedList
            key={sideBarItem.unCollapsedItem.name}
            unCollapsedItem={sideBarItem.unCollapsedItem}
            collapsedItems={sideBarItem?.collapsedItems}
          />
        ))}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: '2rem',
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleLogOut()}
          sx={{
            minWidth: '13rem',
            height: '3rem',
            backgroundColor: 'primary.main',
            color: 'secondary.main',
          }}
        >
          Log out
        </Button>
      </Box>
    </Box>
  );
};
