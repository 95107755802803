import {SyntheticEvent} from 'react';
import {Tab, Tabs} from '@mui/material';

type tabItemType = {
  value: number;
  label: string;
};

interface MainViewTabsProps {
  tabItemList: tabItemType[];
  tabValue: number;
  handleTabChange: (event: SyntheticEvent, newTabValue: number) => void;
}

/**
 * map tabItemList to render multiple tab(s) in tabs
 *
 * @param tabItemList - tab item List for tab(s)
 * @param tabValue - tab value shown on the tabs
 * @param handleTabChange - callback function for access tab event in the parent component
 * @returns - tabs
 */
export const MainViewTabs = ({
  tabItemList,
  tabValue,
  handleTabChange,
}: MainViewTabsProps) => {
  return (
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {tabItemList.map(tabItem => (
        <Tab
          key={tabItem.label}
          value={tabItem.value}
          label={tabItem.label}
          sx={{
            minWidth: '8rem',
            marginInline: '2px',
          }}
          color="primary"
        />
      ))}
    </Tabs>
  );
};
