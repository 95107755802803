import {
  Stack,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import {EmailOutlined} from '@mui/icons-material';

interface NeedToSendPasswordProps {
  email: string;
  handleSetPassword: (password: string) => void;
}

export const NeedToSendPassword = ({
  email,
  handleSetPassword,
}: NeedToSendPasswordProps) => {
  return (
    <Box
      sx={{
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="h3" component="h2">
          Password recovery
        </Typography>
        <Typography variant="body1" component="h2">
          Email: <b>{email}</b>
        </Typography>
      </Stack>
      <Box
        sx={{
          maxWidth: {xs: '90%', sm: '20rem', md: '28rem'},
        }}
      >
        <Stack direction="row">
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="New Password"
            fullWidth={true}
            sx={{
              textAlign: 'left',
              '& .MuiInputBase-root': {
                maxHeight: '5rem',
                height: '100%',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{px: '1.5rem', height: '3.5rem'}}
            onClick={() => handleSetPassword('password')}
          >
            Continue
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
