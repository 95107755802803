export const languageList = [
  {
    label: 'EN',
    value: '', // SET AS DEFAULT
  },
  {
    label: 'CH',
    value: 'Chinese',
  },
  {
    label: 'JP',
    value: 'Japanese',
  },
  {
    label: 'FR',
    value: 'FRENCH',
  },
];
