import {useState} from 'react';

import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';

interface SelectProps {
  itemList: {itemName: string; value: string}[];
}

export const DSSelect = ({itemList}: SelectProps) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const handleSelect = (event: SelectChangeEvent) => {
    setSelectedItem(event.target.value);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <Select
        value={selectedItem}
        displayEmpty
        fullWidth
        onChange={handleSelect}
        sx={{
          // maxWidth: '12rem',
          // center text
          px: 1,
          textAlign: 'center',
          '& .MuiSelect-select': {
            textAlign: 'center',
            justifyContent: 'center',
          },
        }}
        disableUnderline
      >
        {itemList.map(item => (
          <MenuItem key={`${item.value}-${item.itemName}`} value={item.value}>
            {item.itemName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
