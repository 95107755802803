/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AccountBalance,
  Laptop,
  Person,
  Money,
  Notifications,
  CalendarMonth,
  Chat,
  FitnessCenter,
  School,
  AccessTime,
} from '@mui/icons-material';

/**
 * Side bar item list, to be updated upon progress
 * Hiding unused sections for the moment
 */
export const mainViewSideBarItemList = [
  // {
  //   unCollapsedItem: {name: 'Live Business', Icon: AccountBalance},
  // },
  // {
  //   unCollapsedItem: {
  //     name: 'Selected Workers',
  //     Icon: Laptop,
  //   },
  // },
  // {
  //   unCollapsedItem: {name: 'Workers', Icon: Person},
  //   collapsedItems: [{name: 'All Shifts'}, {name: 'Create New Shift'}],
  // },
  {
    unCollapsedItem: {name: 'Notification', Icon: Notifications},
    collapsedItems: [
      {name: 'Create New Text Message', url: '/create-new-text-message'},
      {name: 'View Notification Sent'},
    ],
  },
  // {
  //   unCollapsedItem: {name: 'Appointment', Icon: CalendarMonth},
  //   collapsedItems: [
  //     {name: 'Cheque Pickup Appointments'},
  //     {name: 'Appointment Settings'},
  //   ],
  // },
  // {
  //   unCollapsedItem: {name: 'Chat', Icon: Chat},
  // },
  // {
  //   unCollapsedItem: {name: 'Cheques', Icon: Money},
  //   collapsedItems: [
  //     {name: 'Cheque Issues'},
  //     {name: 'Cheque Requests'},
  //     {name: 'Cheque Request History'},
  //     {name: 'Cheque Issues History'},
  //   ],
  // },
  // {
  //   unCollapsedItem: {name: 'Agency Staff', Icon: Person},
  //   collapsedItems: [
  //     {name: 'All Staff'},
  //     {name: 'Create New Staff'},
  //     {name: 'All Call Center Staff'},
  //     {name: 'Employee Stats'},
  //   ],
  // },
  // {
  //   unCollapsedItem: {name: 'Agency Drivers', Icon: Person},
  //   collapsedItems: [
  //     {name: 'All Assigned Workers'},
  //     {name: 'Export All Rides'},
  //     {name: 'All Drivers'},
  //     {name: 'Create New Driver'},
  //   ],
  // },
  // {
  //   unCollapsedItem: {name: 'Trainings', Icon: FitnessCenter},
  //   collapsedItems: [{name: 'All Trainings'}, {name: 'Create New Training'}],
  // },
  // {
  //   unCollapsedItem: {name: 'Qualifications', Icon: School},
  //   collapsedItems: [
  //     {name: 'All Qualifications'},
  //     {name: 'Create eNew Qualification'},
  //   ],
  // },
  // {
  //   unCollapsedItem: {name: 'Payroll', Icon: Money},
  //   collapsedItems: [{name: 'All Timesheet'}, {name: 'Payroll Settings'}],
  // },
  // {
  //   unCollapsedItem: {name: 'Sign In/ Sign Out', Icon: AccessTime},
  //   collapsedItems: [{name: 'QR Code Scanner'}],
  // },
];
