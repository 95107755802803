import {Box, Typography, Stack, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export const ResetSuccess = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '30vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="column"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Typography variant="h3" fontWeight="fontWeightBold">
          Success
        </Typography>
        <Typography variant="body1" sx={{mt: '-0.5rem', mb: '0.7rem'}}>
          Your password has been changed
        </Typography>
      </Stack>
      <Box sx={{mt: '2rem'}}>
        <Button
          sx={{py: '0.5rem', px: '1rem'}}
          variant="contained"
          onClick={() => navigate('/')}
        >
          Back to Sign in
        </Button>
      </Box>
    </Box>
  );
};
