import {KeyboardArrowRight} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {useState} from 'react';
import {repeatItemList} from './const';

export const ScheduleMessage = (props: {
  openScheduleMessage: boolean;
  setOpenScheduleMessage: (arg0: {
    (prev: boolean): boolean;
    (prev: boolean): boolean;
  }) => void;
  handleOpenModal: (arg0: string) => void;
}) => {
  const [dateTime, setDateTime] = useState<string | null>(moment().format());
  const [isRepeat, setIsRepeat] = useState('yes');

  const handleSetRepeat = (event: SelectChangeEvent) => {
    setIsRepeat(event.target.value);
  };

  return (
    <Box
      display={props.openScheduleMessage ? 'flex' : 'none'}
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        pb="1rem"
      >
        <Typography
          variant="h5"
          pb="3rem"
          sx={{cursor: 'pointer'}}
          onClick={() => props.setOpenScheduleMessage(prev => !prev)}
        >
          Schedule Message
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{xs: 'center', sm: 'start'}}
          gap={2}
        >
          <Typography minWidth={{xs: '6.25rem', sm: '10rem'}} textAlign="end">
            First Send date
          </Typography>
          <Box minWidth={{sm: '10rem'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={props => <TextField {...props} />}
                value={dateTime}
                onChange={newValue => setDateTime(newValue)}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography minWidth={{xs: '6.25rem', sm: '10rem'}} textAlign="end">
            Timezone
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent={{xs: 'center', sm: 'start'}}
        gap={2}
        py={4}
      >
        <Typography minWidth={{xs: '6.25rem', sm: '10rem'}} textAlign="end">
          Repeat
        </Typography>
        <Select
          value={isRepeat}
          displayEmpty
          onChange={handleSetRepeat}
          sx={{
            minWidth: {sm: '10rem'},
            maxWidth: {xs: '12rem'},
            width: 1,
            '& .MuiSelect-select': {
              textAlign: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {repeatItemList.map(item => (
            <MenuItem key={item.itemName} value={item.value}>
              {item.itemName}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Divider />
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={{xs: '1rem'}}
        >
          <Box>
            <Button
              variant="text"
              onClick={() => props.setOpenScheduleMessage(prev => !prev)}
            >
              Cancel Scheduling
            </Button>
          </Box>
          <Box
            maxWidth={{md: '30rem'}}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              onClick={() => props.handleOpenModal('preview')}
            >
              Preview message
            </Button>
            <Button variant="text">Preview in calender</Button>
            <Button
              variant="contained"
              endIcon={<KeyboardArrowRight />}
              sx={{px: {xs: '1.5rem'}}}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
