import {Box, Button} from '@mui/material';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error(error);
  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h2>
          {error.status} {error.statusText}{' '}
          {error.data?.message && error.data.message}{' '}
        </h2>
        Your error has been received, we will fix the error as soon as possible
        <Button
          variant="contained"
          sx={{fontWeight: 'fontWeightMedium', mt: '3rem'}}
          onClick={() => navigate(-1)}
        >
          Go back to last page
        </Button>
      </>
    );
  } else {
    return <div>Oops</div>;
  }
};

const RouteErrorView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5rem',
        width: '100vw',
      }}
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <ErrorBoundary />
    </Box>
  );
};

export default RouteErrorView;
