import {useNavigate} from 'react-router-dom';

import {Stack, Box, Button, Typography} from '@mui/material';

export const EmailSend = () => {
  const email = 'email@email.com';
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="column"
        spacing={1}
        sx={{maxWidth: {xs: '90%', sm: '20rem', md: '28rem'}}}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h3" component="h2">
            Reset your password
          </Typography>
          <Box>
            <Typography variant="body1" component="h2">
              if an account exists for <b>{email}</b> we will send instructions
              for resting your password. Did not get them? Check the email
              address the resend the instructions.
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            pt: '1.5rem',
          }}
          display="flex"
          justifyContent={{xs: 'center', md: 'start'}}
        >
          <Button
            variant="contained"
            sx={{px: '1.5rem'}}
            onClick={() => navigate('/')}
          >
            Back to Sign in
          </Button>
        </Box>
        <Button
          variant="text"
          sx={{
            px: 0,
            fontSize: 'body2',
            justifyContent: {xs: 'center', md: 'start'},
          }}
          // style={{marginInline: '0 auto'}}
        >
          Resend the instruction again
        </Button>
      </Stack>
    </Box>
  );
};
