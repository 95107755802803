import {
  DSCognito,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  isSessionValid,
  googleLogin,
  getCurrentUser,
  signOut,
} from '@digistaff/client-sdk';

const auth = new DSCognito({ClientId: process.env.COGNITO_CLIENT_ID as string});

export {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  isSessionValid,
  googleLogin,
  getCurrentUser,
  signOut,
};
