/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect} from 'react';
import {createContext, useContext, useState} from 'react';
import {auth, getCurrentUser} from './Account';

export interface AuthContextInterface {
  currentUser: any;
  login: Function;
  logOut: Function;
}

export const AuthContext = createContext<AuthContextInterface>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    getCurrentUser(auth)
      .then(({user}: any) => login(user))
      .catch(err => console.log(err));
  }, []);

  const login = (user: any) => {
    setCurrentUser({user});
  };

  const logOut = () => {
    setCurrentUser({logOut: true});
    localStorage.removeItem('dsLastPage');
  };

  return (
    <AuthContext.Provider value={{currentUser, login, logOut}}>
      {children}
    </AuthContext.Provider>
  );
};
