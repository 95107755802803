import {Box, Modal, Typography, IconButton, Divider} from '@mui/material';
import {Cancel} from '@mui/icons-material';

const defaultModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 'none',
  p: {xs: 2, md: 4},
  width: {xs: '95%', md: '80%'},
  maxWidth: {md: '60rem'},
  height: '85%',
  overflow: 'scroll',
};

const defaultFormGroupGeneral = {
  mb: {xs: 2},
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  modalHeader?: React.CSSProperties;
}

export const GlobalModal = ({children, open, onClose, title}: ModalProps) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={defaultModalStyle}>
          <Box sx={defaultFormGroupGeneral}>
            <Typography variant="h5">{title}</Typography>
            <IconButton sx={{p: 0}} onClick={onClose}>
              <Cancel sx={{fontSize: '2rem'}} />
            </IconButton>
          </Box>
          <Divider />
          {children}
        </Box>
      </Modal>
    </>
  );
};
