/* eslint-disable @typescript-eslint/no-explicit-any */
import {Navigate, useLocation} from 'react-router-dom';
import {auth, isSessionValid} from '../providers/Account';

export const PrivateRoute = ({children}: any) => {
  const checkSession = isSessionValid(auth);
  localStorage.setItem('dsLastPage', useLocation().pathname);

  if (!checkSession) {
    return <Navigate to="/" replace />;
  }

  return children;
};
